.img-0, .img-1, .img-2, .img-3, .img-4 {
  animation-duration: 1.5s;
  animation-fill-mode: both;
  animation-name: fadeInUp;
}

/*=== Optional Delays, change values here  ===*/
.img-0 {
  animation-delay: 0.2s
}

.img-1 {
  animation-delay: 1.2s
}

.img-2 {
  animation-delay: 4.0s
}

.img-3 {
  animation-delay: 6.9s
}

.img-4 {
  animation-delay: 8.5s
}

/*==== FADE IN UP ===*/
@keyframes fadeInUp {
  from {
    opacity: 0
  }
  to {
    opacity: 1;
    transform: none
  }
}
