.root {
  background-color: #FFFFFF;
  width: 360px;
  max-width: 95vw;
  padding: 30px 15px 34px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
}

.title {
  text-align: center;
  color: #2F2E37;
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  margin-bottom: 20px;
}

.description {
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #2F2E37;
  margin-bottom: 30px;
}

.imageWrapper {
  font-size: 0;
  line-height: 0;
  margin-bottom: 30px;
}

.tip {
  text-align: center;
  color: #2F2E37;
  font-size: 14px;
  line-height: 140%;
  margin-bottom: 30px;
}

.buttonWrapper {
  width: 100%;
  margin: 0;
}

.button {
  width: 100%;
}