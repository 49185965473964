@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,400&display=swap);
.PaypalPayment_root__3T2vY{min-height:50px}

.CardPayment_root__1ah7I{height:350px;width:100%}.CardPayment_root__1ah7I>*{height:100%}

.PaymentDialog_wrapper__38nrt{position:relative;border-radius:20px;width:340px;max-width:95vw;max-height:95vh;background:white;color:#2F2E37;overflow:hidden;padding:20px 20px 30px;display:flex;flex-direction:column}.PaymentDialog_header__1yxxZ{flex-shrink:0;font-family:Open Sans, sans-serif;font-weight:600;font-size:18px;display:flex;flex-direction:column;border-bottom:1px solid #BDBDBD;padding-top:10px;padding-bottom:10px}.PaymentDialog_body__1am42{min-height:475px;padding-top:20px;height:100%}.PaymentDialog_bodyWrapper__1ao-Y{overflow-y:auto;position:relative;flex-grow:1}.PaymentDialog_bodyInProgress__3F4Lk{visibility:hidden}.PaymentDialog_controlWrapper__1T2wp{flex-shrink:0;cursor:pointer;padding:5px;display:inline-flex;align-items:center;justify-content:center;margin-left:auto}.PaymentDialog_control__3aVJ5{fill:#2F2E37;width:16px;height:16px}.PaymentDialog_priceWrapper__3Z6HJ{width:100%;display:flex;align-items:center;justify-content:space-between}.PaymentDialog_divider__2zrc5{color:#2F2E37;margin:15px auto;position:relative;text-align:center;overflow-x:hidden}.PaymentDialog_dividerLabel__3kgJw{text-transform:uppercase;position:relative;z-index:1}.PaymentDialog_dividerLabel__3kgJw:before,.PaymentDialog_dividerLabel__3kgJw:after{content:"";position:absolute;height:0;width:1000px;top:50%;right:100%;transform:translateY(-50%);border-top:thin solid #c4c4c4;transition:border-color 0.15s ease-in-out}.PaymentDialog_dividerLabel__3kgJw::after{left:calc(100% + 8px)}.PaymentDialog_dividerLabel__3kgJw::before{right:calc(100% + 8px)}.PaymentDialog_loaderWrapper__1wAdC{pointer-events:none;position:absolute;z-index:200;top:0;right:0;bottom:0;left:0;background:white;display:flex;align-items:center;justify-content:center}

.PaymentFailedDialogContent_root__35fyx{flex-grow:1;display:flex;flex-direction:column;align-items:center;min-height:320px;padding-top:10px}.PaymentFailedDialogContent_title__1G2V-{font-family:Open Sans, sans-serif;font-weight:600;font-size:18px;line-height:24px;text-align:center;margin-bottom:10px;width:100%}.PaymentFailedDialogContent_text__1O22u{width:100%;text-align:center;font-size:14px;line-height:22px;color:#828282;margin-bottom:10px}.PaymentFailedDialogContent_iconWrapper__2b84l{margin-top:auto;margin-bottom:auto}.PaymentFailedDialogContent_buttonWrapper__3q187{width:100%;margin-top:10px;margin-bottom:0;display:flex;justify-content:center}.PaymentFailedDialogContent_button__3-3uY{width:100%}

.PaymentFailedDialog_wrapper__2wt5G{position:relative;border-radius:20px;width:340px;max-width:95vw;max-height:95vh;background:white;color:#2F2E37;overflow:hidden;padding:20px 20px 30px;display:flex;flex-direction:column}.PaymentFailedDialog_controlWrapper__1UfGM{flex-shrink:0;cursor:pointer;padding:5px;display:inline-flex;align-items:center;justify-content:center;margin-left:auto}.PaymentFailedDialog_control__1Mcda{fill:#2F2E37;width:16px;height:16px}

.AdditionalDiscountModal_root__1WKKI{background-color:#FFFFFF;width:360px;max-width:95vw;padding:30px 15px 34px;display:flex;flex-direction:column;align-items:center;border-radius:20px}.AdditionalDiscountModal_title__3C3eR{text-align:center;color:#2F2E37;font-size:24px;line-height:28px;font-weight:700;margin-bottom:20px}.AdditionalDiscountModal_description__2K0pu{font-size:18px;line-height:21px;text-align:center;color:#2F2E37;margin-bottom:30px}.AdditionalDiscountModal_imageWrapper__zZCKg{font-size:0;line-height:0;margin-bottom:30px}.AdditionalDiscountModal_tip__33IsY{text-align:center;color:#2F2E37;font-size:14px;line-height:140%;margin-bottom:30px}.AdditionalDiscountModal_buttonWrapper__3mCEs{width:100%;margin:0}.AdditionalDiscountModal_button__1oYRf{width:100%}

.img-0,.img-1,.img-2,.img-3,.img-4{animation-duration:1.5s;animation-fill-mode:both;animation-name:fadeInUp}.img-0{animation-delay:0.2s}.img-1{animation-delay:1.2s}.img-2{animation-delay:4.0s}.img-3{animation-delay:6.9s}.img-4{animation-delay:8.5s}@keyframes fadeInUp{from{opacity:0}to{opacity:1;transform:none}}

*,*::before,*::after{box-sizing:border-box}img{max-width:100%}iframe{margin:0;padding:0;border:0;font:inherit;vertical-align:baseline}ul,ol{list-style-type:none;padding-left:0;margin:0}p,h1,h2,h3,h4,h5,h6,label{margin:0}a{color:inherit;text-decoration:none;cursor:pointer}a:focus,a:hover{text-decoration:none}button,input[type="submit"],input[type="file"],input[type="button"]{border-radius:0;color:inherit;padding:0}[type=button]:not(:disabled),[type=reset]:not(:disabled),[type=submit]:not(:disabled),button:not(:disabled){cursor:pointer}input,button,textarea,select{letter-spacing:inherit;font-family:inherit;font-size:inherit;-webkit-appearance:none;-moz-appearance:none;appearance:none;border:none}button{background:none}html,body,#root{height:100%}body{font-family:"Open Sans", sans-serif;margin:0;line-height:1.5;-webkit-tap-highlight-color:transparent}

